/*
 * IBM Confidential
 * 5737-M96
 * (C) Copyright IBM Corp. 2020, 2023
 */
import React, { useEffect, useState,useRef } from 'react';
import PropTypes from 'prop-types';
import { UserAvatar20 } from '@carbon/icons-react';
import { Logout20 } from '@carbon/icons-react';
import Link from 'carbon-components-react/es/components/UIShell/Link';
import {
  Header,
  HeaderName,
  HeaderMenuButton,
} from 'carbon-components-react/lib/components/UIShell';
import Button from 'carbon-components-react/es/components/Button';

const  logoutFunction = () => {
  ManageIQ.logoutInProgress = true;
};
export const Navbar = ({
  applianceName, brandUrl, currentUser, isSideNavExpanded, onClickSideNavExpand,
}) => {
  const userid = `${currentUser.userid}`;
  const username = `${currentUser.name}`;
  const appName = `${applianceName}`;
  const [showDropdown,setShowDropdown] = useState(false);
  const navContainer = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      if (navContainer.current && !navContainer.current.contains(event.target)) {
        setShowDropdown(false);
      }
    }
    function handleTabOutside(event) {
      if (event.key ==='Tab' && navContainer.current && !navContainer.current.contains(event.target)) {
        setShowDropdown(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleTabOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleTabOutside);
    }
  });


  return (
    <div className="container">
      <Header aria-label="IBM Automation">
        <HeaderMenuButton
          aria-label={__('Open menu')}
          isCollapsible
          onClick={onClickSideNavExpand}
          isActive={isSideNavExpanded}
        />
        <HeaderName href="/dashboard/start_url" prefix="IBM" >
          <span className="parent-product-label">Automation </span> <span className="product-label">| Infrastructure Management</span>
        </HeaderName>
        <div ref={navContainer}>
          <Button
            className="navbar-userinfo-dropbtn"
            onClick={() => setShowDropdown(!showDropdown)}
            renderIcon={UserAvatar20}
            iconDescription={__('user info')}
          />
          {showDropdown && (
            <div id="userinfo-dropdown-div" className="navbar-dropdown-content">
              <ul className="navbar-dropdown-list">
                <li>
                  <div className="navbar-dropdown-userinfo" >
                    <span >{userid}</span>
                    <span className="user-info-role">{username}</span>
                    <span className="user-info-role">{appName}</span>
                  </div>
                </li>
                <Link href="/dashboard/logout" target="_self" onClick={logoutFunction}>  <Logout20 /> {__('Logout')} </Link>
              </ul>
            </div>
          )}
        </div>
      </Header>
    </div>
  );
};

Navbar.propTypes = {
  applianceName: PropTypes.string.isRequired,
  currentUser:  PropTypes.object.isRequired,
  isSideNavExpanded: PropTypes.bool.isRequired,
  onClickSideNavExpand: PropTypes.func.isRequired,
};
