/*
 * IBM Confidential
 * 5737-M96
 * (C) Copyright IBM Corp. 2020, 2023
 */
// override Navbar stub
import { Navbar } from '../menu/navbar.js'
// override menu css (colors)
import  '../stylesheets/brand.scss'
import  '../stylesheets/navbar.scss'
ManageIQ.component.addReact('menu.Navbar', Navbar, { override: true });
// override menu settings
ManageIQ.component.getReact('menu.MainMenu').defaultProps.showLogo = false;
ManageIQ.component.getReact('menu.MainMenu').defaultProps.showMenuCollapse = false;
ManageIQ.component.getReact('menu.MainMenu').defaultProps.showUser = false;
